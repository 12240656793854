import React, { useEffect, useState } from 'react';
import { Button, Popconfirm, Tooltip, Table, notification } from 'antd';
import './style.css';
import { TableColumnsType, TableProps, Input } from 'antd';
import { useNavigate } from 'react-router-dom';
import { CloudDownloadOutlined, FilePdfOutlined, DeleteOutlined, EditOutlined, SmileOutlined, StopOutlined } from '@ant-design/icons';
import SisLoader from '../../widgets/loader';
import { CATEGORIES_LIST, DELETE_CATEGORY, STATUS_CATEGORY } from '../../apis/apis';

import { BACKEND_URL } from '../../config/config';
import { render } from '@testing-library/react';

const CategoriesList = () => {
    const navigate = useNavigate();
    const [loader, set_loader] = useState(true);
    const [errors, set_errors] = useState([]);
    const [popStatusDescription, set_popStatusDescription] = useState('Do you want to change Satus of this Course Category');
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredData, setFilteredData] = useState([]);

    // Form State
    const [title, set_title] = useState('');
    const [table_list, set_table_list] = useState([]);
    const STATUS_API = async (id) => {
        set_loader(true)
        const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
        const FORM_DATA = new FormData();
        FORM_DATA.append('token', USER_DATA.token);
        FORM_DATA.append('id', id);
        const API_RESPONSE = await STATUS_CATEGORY(FORM_DATA);
        if (API_RESPONSE.data.status) {
            notification.open({
                message: 'Success!!',
                description: 'Category status Successfully changed.',
                icon: <SmileOutlined style={{ color: 'green' }} />,
            });
            navigate('/categories-list')
        } else {
            set_errors(API_RESPONSE.data.errors);
            set_loader(false)
        }
    }

    // Search
    const handleSearch = (e) => {
        const query = e.target.value;
        setSearchQuery(query);
        const filtered = table_list.filter(item =>
            item.title.toLowerCase().includes(query.toLowerCase())
            // item.parent_title.toLowerCase().includes(query.toLowerCase())
        );
        if (query === '') {
            setFilteredData(table_list);
        } else {
            setFilteredData(filtered);
        }
    };

    // Delete
    const DELETE_API = async (id) => {
        set_loader(true)
        const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
        const FORM_DATA = new FormData();
        FORM_DATA.append('token', USER_DATA.token);
        FORM_DATA.append('id', id);
        const API_RESPONSE = await DELETE_CATEGORY(FORM_DATA);
        if (API_RESPONSE.data.status) {
            notification.open({
                message: 'Success!!',
                description: 'Category Successfully deleted.',
                icon: <SmileOutlined style={{ color: 'green' }} />,
            });
            navigate('/categories-list')
        } else {
            set_errors(API_RESPONSE.data.errors);
            set_loader(false)
        }
    }

    const handleStatusText = (id, status) => {
        if (status === 1) {
            set_popStatusDescription('Do you want to make this category Inactive');
        } else { set_popStatusDescription('Do you want to make this category Active'); }
    }

    const columns = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
            sorter: (a, b) => a.id - b.id,
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            sorter: (a, b) => a.title.localeCompare(b.title),
        },
        Table.EXPAND_COLUMN,
        {
            title: 'Parent',
            dataIndex: 'parent_title',
            key: 'parent_title',
            render: (text, record) => {
                if (record.parent_title) {
                    return (
                        <> {record.parent_title}</>
                    );
                } else {
                    return (
                        <>No Parent</>
                    );
                }
            }
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => {
                if (record.status) {
                    return (
                        <span className="table-status-activate">Active</span>
                    );
                } else {
                    return (
                        <span className="table-status-deactivate">Inactive</span>
                    );
                }
            }
        },
        {
            title: 'Action',
            key: 'Action',
            render: (text, record) => {
                return (
                    <>
                        <Popconfirm
                            title="Change Status"
                            description={popStatusDescription}
                            onConfirm={() => {
                                STATUS_API(btoa(record.id))
                            }}
                            // onCancel={cancel}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button type="primary" size="small" style={{ backgroundColor: "#888888", marginRight: "5px" }} onClick={() => handleStatusText(record.id, record.status)} >

                                <div class="tooltip"> <StopOutlined /> <span class="tooltiptext">Status Change</span> </div>


                            </Button>
                        </Popconfirm>

                        <Button type="primary" size="small" onClick={() => navigate('/edit-category/' + btoa(record.id))} style={{ marginRight: "5px" }}>

                            <div class="tooltip"> <EditOutlined /> <span class="tooltiptext">Edit n View</span> </div>


                        </Button>

                        <Popconfirm
                            title="Change Status"
                            description="Are you sure to delete this category?"
                            onConfirm={() => {
                                DELETE_API(btoa(record.id))
                            }}
                            // onCancel={cancel}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button type="primary" size="small" danger>
                                <div class="tooltip"> <DeleteOutlined /> <span class="tooltiptext">Delete</span> </div>

                            </Button>
                        </Popconfirm>
                    </>
                )
            }

        },
    ];

    // DB list
    const LIST_API = async () => {
        const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
        const FORM_DATA = new FormData();
        FORM_DATA.append('token', USER_DATA.token);
        const API_RESPONSE = await CATEGORIES_LIST(FORM_DATA);
        if (API_RESPONSE?.data?.status) {
            var filteredCat = API_RESPONSE?.data?.categories.filter(item => item.soft_delete === 0);
            set_table_list(filteredCat);
            setFilteredData(API_RESPONSE.data.categories);

            set_loader(false);
        } else {
            set_loader(false);
        }
    }
    useEffect(() => {
        LIST_API();
    }, [loader]);


    return (
        <div>
            <div className='theme-content-head'>
                <div className='theme-content-left-head'>
                    <h3>Categories List</h3>
                </div>
                <div className='theme-content-right-head'>
                    <Button type='primary' onClick={() => navigate('/add-category')}>Add Category</Button>

                    {/* CSV Download button */}
                    <Tooltip title="Download Users List CSV">
                        <Button type='primary' onClick={() => window.location = BACKEND_URL + '/download-categories/' + JSON.parse(localStorage.getItem('sis_user_data')).token} ghost style={{ marginLeft: "5px" }}><CloudDownloadOutlined /></Button>
                    </Tooltip>

                    {/* PDF Download button  */}
                    <Tooltip title="Download Users List PDF">
                        <Button type='primary' onClick={() => window.location = BACKEND_URL + '/download-categories-pdf/' + JSON.parse(localStorage.getItem('sis_user_data')).token} ghost style={{ marginLeft: "5px" }}><FilePdfOutlined /></Button>
                    </Tooltip>
                </div>
            </div>
            <div className='theme-content-head'>
                <div className='input-box'>
                    <Input
                        type="text"
                        placeholder="Search..."
                        value={searchQuery}
                        onChange={handleSearch}
                    />
                    {errors?.code && <span style={{ color: "red" }}>{errors?.code[0]}</span>}
                </div>
            </div>
            {loader ? <SisLoader /> :
                <Table
                    columns={columns}
                    expandable={{
                        expandedRowRender: (record) => (
                            <>
                            <div style={{ backgroundColor:"#FFF", padding: "8px", }} dangerouslySetInnerHTML={{__html: record.description}}></div>
                            </>
                        ),
                    }}

                    dataSource={filteredData}
                />
            }

        </div>
    );
};

export default CategoriesList;