import React, { useEffect, useRef, useState } from 'react';
import { Button, Checkbox, DatePicker, Input, Select, Spin, Table, notification } from 'antd';
import type { TableColumnsType, TableProps } from 'antd';
import { CloseOutlined, CloudUploadOutlined, EyeInvisibleOutlined, EyeTwoTone, LoadingOutlined, SmileOutlined } from '@ant-design/icons';
import SisLoader from '../../../widgets/loader';
import Item from 'antd/es/list/Item';
import { useNavigate, useParams } from 'react-router-dom';
import { Editor } from '@tinymce/tinymce-react';
import { ADD_CALENDAR, EDIT_CALENDAR, UPDATE_CALENDAR } from '../../../apis/apis';
import dayjs from 'dayjs';
import SemesterList from '../semester/semesterList';

const EditCalendar = () => {
    const { id } = useParams();
    const { RangePicker } = DatePicker;
    const editorRef = useRef(null);
    const navigate = useNavigate();
    const [loader, set_loader] = useState(false);
    const [errors, set_errors] = useState([]);
    const [page_loader, set_page_loader] = useState(true)
    // Form State
    const [title, set_title] = useState('');
    const [description, set_description] = useState('');
    const [calendar_year, set_calendar_year] = useState('');
    const [calendar_start, set_calendar_start] = useState('');
    const [calendar_end, set_calendar_end] = useState('');


    const VIEW_API = async () => {
        const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
        const FORM_DATA = new FormData();
        FORM_DATA.append('id', id);
        FORM_DATA.append('token', USER_DATA.token);
        const EDIT_ROLE_API_RESPONSE = await EDIT_CALENDAR(FORM_DATA);
        if (EDIT_ROLE_API_RESPONSE?.data?.status) {
            set_title(EDIT_ROLE_API_RESPONSE?.data?.calendar?.title);
            set_calendar_start(EDIT_ROLE_API_RESPONSE?.data?.calendar?.year_start_date);
            set_calendar_end(EDIT_ROLE_API_RESPONSE?.data?.calendar?.year_end_date);
            set_description(EDIT_ROLE_API_RESPONSE?.data?.calendar?.description)
            set_page_loader(false)

        } else {
            set_errors(EDIT_ROLE_API_RESPONSE.data.errors);
        }
    }

    const select_date = (date, dateString, type) => {
        if (type === 'start_date') {
            set_calendar_start(dateString);
        } else {
            set_calendar_start(dateString);
        }
    }
    const UPDATE_API = async () => {
        set_loader(true)
        const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
        const FORM_DATA = new FormData();
        FORM_DATA.append('token', USER_DATA.token);
        FORM_DATA.append('id', id);
        FORM_DATA.append('title', title);
        FORM_DATA.append('description', editorRef.current.getContent());
        FORM_DATA.append('year_start_date', calendar_start);
        FORM_DATA.append('year_end_date', calendar_end);
        const API_RESPONSE = await UPDATE_CALENDAR(FORM_DATA);
        if (API_RESPONSE?.data?.status) {
            notification.open({
                message: 'Success!!',
                description: 'Calendar Successfully Updated.',
                icon: <SmileOutlined style={{ color: 'green' }} />,
            });
            set_loader(false)
            set_errors([])
        } else {
            set_errors(API_RESPONSE.data.errors);
            set_loader(false)
        }
    }
    useEffect(() => {
        VIEW_API();
    }, [])
    return (
        <>
      
            <div>
                <div className='theme-content-head'>
                    <div className='theme-content-left-head'>
                        <h3>Academic Calendar</h3>
                    </div>
                    <div className='theme-content-right-head'>

                    </div>
                </div>
                <div className='sis-tab-menu'>
                    <span className='active'>Academic Calendar</span>
                    <span  onClick={() =>  navigate('/calendar-semester/'+id)}>Semesters</span>
                    <span  onClick={() =>  navigate('/calendar-schedule/'+id)}>Schedules</span>
                </div>
                {page_loader ? <SisLoader /> : <>
                <div className='sis-tab-content'>
                    <div className='theme-content-head'>
                        <div className='theme-content-left-head'>
                            <h3>Update Calendar</h3>
                        </div>
                        <div className='theme-content-right-head'>
                            {/* <Button type='primary' onClick={() => navigate('/add-category')}>Add Calendar</Button> */}
                        </div>
                    </div>
                    <div className='input-box'>
                        <label htmlFor="name">Title<i style={{ color: "red" }}>*</i></label>
                        <Input value={title} placeholder="Title" id='title' onChange={(e) => set_title(e.target.value)} />
                        {errors?.title && <><span style={{ color: "red" }}>{errors?.title[0]}</span></>}
                    </div>
                    <div className='row'>
                        <div className='col-6'>
                            <label htmlFor="name">Calendar Start Date<i style={{ color: "red" }}>*</i></label>
                            <DatePicker value={calendar_start ? dayjs(calendar_start) : ''} onChange={(date, dateString) => select_date(date, dateString, 'start_date')} style={{ width: "100%" }} />
                            {errors?.year_start_date && <><span style={{ color: "red" }}>{errors?.year_start_date[0]}</span></>}
                        </div>
                        <div className='col-6'>
                            <label htmlFor="name">Calendar End Date<i style={{ color: "red" }}>*</i></label>
                            <DatePicker value={calendar_end ? dayjs(calendar_end) : ''} onChange={(date, dateString) => select_date(date, dateString, 'end_date')} style={{ width: "100%" }} />
                            {errors?.year_end_date && <><span style={{ color: "red" }}>{errors?.year_end_date[0]}</span></>}
                        </div>
                    </div>

                    <div className='input-box'>
                        <label htmlFor="name">Description<i style={{ color: "red" }}>*</i></label>
                        <Editor
                            apiKey='i6i6aki8vkxt19vlfxol49qa6zukk6lry8hgtzka6agthn0x'
                            onInit={(evt, editor) => editorRef.current = editor}
                            initialValue={description}
                            init={{
                                height: 250,
                                menubar: false,
                                plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount imagetools',
                                toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image code media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat',
                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                            }}
                        />
                        {errors?.description && <><span style={{ color: "red" }}>{errors?.description[0]}</span></>}
                    </div>
                    <div className='input-box'>
                        {loader ? <>
                            <Button type="primary"><Spin indicator={<LoadingOutlined style={{ fontSize: '12px', color: "#fff", marginRight: "5px" }} />} /> Update Calendar</Button>
                        </> : <>
                            <Button type="primary" onClick={UPDATE_API}>Update Calendar</Button>
                        </>}
                    </div>


                </div>
                </>}
            </div>
           
        </>
    );
};

export default EditCalendar;