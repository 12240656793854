import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Input, Spin, Table, notification } from 'antd';
import './style.css';
import type { TableColumnsType, TableProps } from 'antd';
import { LoadingOutlined, SmileOutlined } from '@ant-design/icons';
import { ADD_ROLE, DB_LIST } from '../../apis/apis';
import SisLoader from '../../widgets/loader';
import Item from 'antd/es/list/Item';
import { useNavigate } from 'react-router-dom';

const AddRole = () => {
    const navigate = useNavigate();
    const [loader, set_loader] = useState(false);


    // Form State
    const [title, set_title] = useState('');
    const [description, set_description] = useState('');
    const [permissions, set_permissions] = useState([]);
    const [db_loader, set_db_loader] = useState(true);
    const [select_all, set_select_all] = useState(false);
    const [errors, set_errors] = useState([]);

    // DB list
    const DB_LIST_API = async () => {
        const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
        const FORM_DATA = new FormData();
        FORM_DATA.append('token', USER_DATA.token);
        const DB_LIST_API_RESPONSE = await DB_LIST(FORM_DATA);
        if (DB_LIST_API_RESPONSE.data.status) {
            set_permissions(DB_LIST_API_RESPONSE.data.tables);
            set_db_loader(false);
        } else {
            set_errors(DB_LIST_API_RESPONSE.data.errors);
            set_db_loader(false);
        }
    }


    useEffect(() => {
        DB_LIST_API();
    }, []);


    const update_permissions = (e, name, type) => {
        if (e.target.checked) {
            if (permissions.find(obj => obj.hasOwnProperty('name') && obj['name'] === name)) {
                var new_per = permissions;
                var ROW_INDEX = new_per.findIndex(obj => obj['name'] === name);
                var item = new_per[ROW_INDEX];
                item[type] = true;
                new_per[ROW_INDEX] = item;
                console.log(new_per);
                set_permissions(new_per);
            }
            e.target.checked = true;
        } else {
            if (permissions.find(obj => obj.hasOwnProperty('name') && obj['name'] === name)) {
                var new_per = permissions;
                var ROW_INDEX = new_per.findIndex(obj => obj['name'] === name);
                var item = new_per[ROW_INDEX];
                item[type] = false;
                new_per[ROW_INDEX] = item;
                console.log(new_per);
                set_permissions(new_per);
                set_select_all(false);
            }
        }

    }
    const update_all_permissions = (e) => {
        if (e.target.checked) {
            set_select_all(true);
            var new_per = permissions;
            new_per?.map((item) => {
                item.create = true;
                item.update = true;
                item.status = true;
                item.delete = true;
            })
            set_permissions(new_per);
        } else {
            set_select_all(false);
            var new_per = permissions;
            new_per?.map((item) => {
                item.create = false;
                item.update = false;
                item.status = false;
                item.delete = false;
            })
            set_permissions(new_per);
        }

    }
    const checked_set = (name, type) => {
        if (permissions.find(obj => obj.hasOwnProperty('name') && obj['name'] === name)) {
            var new_per = permissions;
            var ROW_INDEX = new_per.findIndex(obj => obj['name'] === name);
            if (ROW_INDEX[type]) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }
    const ADD_ROLE_API = async () => {
        set_loader(true)
        const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
        const FORM_DATA = new FormData();
        FORM_DATA.append('token', USER_DATA.token);
        FORM_DATA.append('title', title);
        FORM_DATA.append('description', description);
        FORM_DATA.append('all_permissions', select_all ? 1 : 0);
        FORM_DATA.append('permissions', JSON.stringify(permissions));
        const ADD_ROLE_API_RESPONSE = await ADD_ROLE(FORM_DATA);
        if (ADD_ROLE_API_RESPONSE.data.status) {
            notification.open({
                message: 'Success!!',
                description: 'Role Successfully added.',
                icon: <SmileOutlined style={{ color: 'green' }} />,
            });
            navigate('/role-list')
        } else {
            set_errors(ADD_ROLE_API_RESPONSE.data.errors);
            set_loader(false)
        }
    }
    return (
        <div>
            <div className='theme-content-head'>
                <div className='theme-content-left-head'>
                    <h3>Add Role</h3>
                </div>
                <div className='theme-content-right-head'>

                </div>
            </div>
            <div className='common-form'>
                {errors?.try && <><span style={{ color: "red" }}>{errors?.try[0]}</span></>}
                {errors?.catch && <><span style={{ color: "red" }}>{errors?.catch[0]}</span></>}
                <div className='row'>
                    <div className='col-12'>
                        <div className='input-box'>
                            <label htmlFor="title">Role Title<i style={{ color: "red" }}>*</i></label>
                            <Input placeholder="Role Title" id='title' onChange={(e) => set_title(e.target.value)} />
                            {errors?.title && <><span style={{ color: "red" }}>{errors?.title[0]}</span></>}
                        </div>

                    </div>
                    <div className='col-12'>
                        <div className='input-box'>
                            <label htmlFor="title">Role Description<i style={{ color: "red" }}>*</i></label>
                            <Input.TextArea placeholder="Role Title" id='description' onChange={(e) => set_description(e.target.value)} />
                            {errors?.description && <><span style={{ color: "red" }}>{errors?.description[0]}</span></>}
                        </div>
                    </div>
                    <div className='col-12'>

                        <label htmlFor="title">Access level/Permissions</label><br></br><br></br>
                        <Checkbox checked={select_all} onChange={(e) => update_all_permissions(e)}> Select All</Checkbox>
                        {db_loader ? <SisLoader /> : <>
                            <div className='row' style={{ marginTop: "20px" }}>

                                {permissions?.length > 0 ? <>
                                    {permissions?.map((item, index) => (
                                        <>
                                            {select_all ? <>
                                                <div className='col-3'>
                                                    <h4 style={{ marginBottom: "10px", textTransform: "uppercase" }}>{item.name}</h4>
                                                    <Checkbox checked={true} onChange={(e) => update_permissions(e, item.name, 'create')}> Create</Checkbox><br></br>
                                                    <Checkbox checked={true} onChange={(e) => update_permissions(e, item.name, 'update')}> Update</Checkbox><br></br>
                                                    <Checkbox checked={true} onChange={(e) => update_permissions(e, item.name, 'status')}> Status</Checkbox><br></br>
                                                    <Checkbox checked={true} onChange={(e) => update_permissions(e, item.name, 'delete')}> Delete</Checkbox>
                                                </div>
                                            </> : <>
                                                <div className='col-3'>
                                                    <h4 style={{ marginBottom: "10px", textTransform: "uppercase" }}>{item.name}</h4>
                                                    <Checkbox onChange={(e) => update_permissions(e, item.name, 'create')}> Create</Checkbox><br></br>
                                                    <Checkbox onChange={(e) => update_permissions(e, item.name, 'update')}> Update</Checkbox><br></br>
                                                    <Checkbox onChange={(e) => update_permissions(e, item.name, 'status')}> Status</Checkbox><br></br>
                                                    <Checkbox onChange={(e) => update_permissions(e, item.name, 'delete')}> Delete</Checkbox>
                                                </div>
                                            </>}

                                        </>
                                    ))}
                                </> : <>
                                    <div className='col-12'>
                                        <p>Data Empty</p>
                                    </div>
                                </>}
                            </div>
                        </>}
                        <div className='input-box'>
                            {loader ? <>
                                <Button type="primary"><Spin indicator={<LoadingOutlined style={{ fontSize: '12px', color: "#fff", marginRight: "5px" }} />} /> Save</Button>
                            </> : <>
                                <Button type="primary" onClick={ADD_ROLE_API}>Save</Button>
                            </>}
                        </div>

                    </div>
                </div>

            </div>

        </div>
    );
};

export default AddRole;