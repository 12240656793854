/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Button, Input, Select, Spin, notification } from "antd";
import {
  CloudUploadOutlined,
  EyeInvisibleOutlined,
  EyeTwoTone,
  LoadingOutlined,
  SmileOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { UPDATE_FACULTY, VIEW_FACULTY, SIS_STORAGE } from "../../apis/apis";
import { useNavigate, useParams } from "react-router-dom";
import SisLoader from "../../widgets/loader";

const EditFaculty = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loader, set_loader] = useState(false);
  const [name, set_name] = useState("");
  const [phone, set_phone] = useState("");
  const [email, set_email] = useState("");
  const [address, set_address] = useState("");
  const [image, set_image] = useState("");
  const [department, set_academic_division] = useState("");
  const [url_image, set_url_image] = useState("");
  const [designation, set_designation] = useState([]);
  const [second_designation, set_second_designation] = useState("");
  const [joiningDate, set_joiningDate] = useState("");
  const [salary, set_salary] = useState("");
  const [errors, set_errors] = useState([]);
  const [page_loader, set_page_loader] = useState(true);

  const academic_divisions_list = ['Business Administration Department', 'Diagnostic Imaging Program', 'English Studies', 'Finance & Investment Department', 'General Education Program', 'Marketing Management Department', 'Research & Development', 'Library'];

  const designations_list = {
      'Business Administration Department': ['Dean of Business Studies', 'Dean of Students', 'Professors', 'Associate Professor', 'Assistant Professor', 'Instructor', 'Teaching assistant'],
      'Diagnostic Imaging Program': ['Director (DI Program)', 'Advanced DI Program Asst. Prof', 'Asst. Professors', 'DI Program Co-Ordinator', 'Instructor', 'Director (DI Lab & associate Professor)', 'DI Lab Assistants'],
      'English Studies': ['Chairman', 'Associate Professor'],
      'Finance & Investment Department': ['Chairman', 'Professor', 'Associate Professor'],
      'General Education Program': ['Director', 'Professors', 'Assistant Professors'],
      'Marketing Management Department': ['Chairman', 'Associate Professor'],
      'Research & Development': ['Institute Research Analyst'],
      'Library': ['Head', 'Library Assistant'],
  };

  useEffect(() => {
    const VIEW_API = async () => {
      try {
        const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
        const FORM_DATA = new FormData();
        FORM_DATA.append("id", id);
        FORM_DATA.append("token", USER_DATA.token);
        const VIEW_FACULTY_API_RESPONSE = await VIEW_FACULTY(FORM_DATA);
        console.log(VIEW_FACULTY_API_RESPONSE);
        if (VIEW_FACULTY_API_RESPONSE.data.status) {
          const userData = VIEW_FACULTY_API_RESPONSE?.data?.faculty;
          set_name(userData.name);
          set_phone(userData.phone);
          set_email(userData.email);
          set_address(userData.address);
          set_academic_division(userData.department);
          set_second_designation(userData.designation);
          set_image(userData.image);
          set_page_loader(false);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    VIEW_API();
  }, [id]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    set_image(file);
  };

  const handleRemoveImage = () => {
    set_image("");
  };

  const onacademicDivChange = (value) => {
    set_academic_division(value);
    set_designation(designations_list[value]);
    set_second_designation(designations_list[value][0]);
  };

  const UPDATE_API = async () => {
    // try {
    set_loader(true);
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("token", USER_DATA.token);
    FORM_DATA.append("id", id);
    FORM_DATA.append("name", name);
    FORM_DATA.append("phone", phone);
    FORM_DATA.append("email", email);
    FORM_DATA.append("image", image);
    FORM_DATA.append("address", address);
    FORM_DATA.append("department", department);
    FORM_DATA.append("designation", second_designation);
    FORM_DATA.append("joiningDate", joiningDate);
    FORM_DATA.append("salary", salary);
    const API_RESPONSE = await UPDATE_FACULTY(FORM_DATA);
    if (API_RESPONSE.data.status) {
      notification.open({
        message: "Success!!",
        description: "Faculty Details Successfully Updated.",
        icon: <SmileOutlined style={{ color: "green" }} />,
      });
      navigate("/facultiesList");
    } else {
      set_errors(API_RESPONSE.data.errors);
      set_loader(false);
    }
    // } catch (error) {
    // console.error('Error updating Faculty:', error);
    // } finally {
    // set_loader(false);
    // }
  };

  return (
    <div>
      <div className="theme-content-head">
        <div className="theme-content-left-head">
          <h3>Edit Faculty</h3>
        </div>
        <div className="theme-content-right-head"></div>
      </div>
      <div className="common-form">
        {page_loader ? (
          <SisLoader />
        ) : (
          <>
            {errors?.try && (
              <>
                <span style={{ color: "red" }}>{errors?.try[0]}</span>
              </>
            )}
            {errors?.catch && (
              <>
                <span style={{ color: "red" }}>{errors?.catch[0]}</span>
              </>
            )}

            <div className="row">
              <div className="col-12">
                {/* name */}
                <div className="input-box">
                  <label htmlFor="name">
                    Name<i style={{ color: "red" }}>*</i>
                  </label>
                  <Input
                    value={name}
                    placeholder="Name"
                    id="name"
                    onChange={(e) => set_name(e.target.value)}
                  />

                  {errors?.name && (
                    <>
                      <span style={{ color: "red" }}>{errors?.name[0]}</span>
                    </>
                  )}
                </div>

                {/* Phone */}
                <div className="input-box">
                  <label htmlFor="phone">
                    Phone Number<i style={{ color: "red" }}>*</i>
                  </label>
                  <Input
                    value={phone}
                    maxLength={10}
                    placeholder="Phone Number"
                    id="phone"
                    onChange={(e) => set_phone(e.target.value)}
                  />

                  {errors?.phone && (
                    <>
                      <span style={{ color: "red" }}>{errors?.phone[0]}</span>
                    </>
                  )}
                </div>

                {/* Email */}
                <div className="input-box">
                  <label htmlFor="email">
                    Email<i style={{ color: "red" }}>*</i>
                  </label>
                  <Input
                    value={email}
                    placeholder="Email"
                    id="email"
                    onChange={(e) => set_email(e.target.value)}
                  />
                  {errors?.email && (
                    <>
                      <span style={{ color: "red" }}>{errors?.email[0]}</span>
                    </>
                  )}
                </div>

                {/* department */}
                <div className="input-box">
                  <label htmlFor="Select-Academic-Division">
                    Select Academic Division<i style={{ color: "red" }}>*</i>
                  </label>
                  <br></br>
                  <Select
                    placeholder="Select Academic Division"
                    value={department}
                    onChange={onacademicDivChange}
                    style={{ width: "100%" }}
                    options={academic_divisions_list.map((item) => ({
                      value: item,
                      label: item,
                    }))}
                  />
                  {errors?.department && (
                    <>
                      <span style={{ color: "red" }}>
                        {errors?.department[0]}
                      </span>
                    </>
                  )}
                </div>

                {/* Designation */}
                <div className="input-box">
                  <label htmlFor="Select-Designation">Select Designation</label>
                  <br></br>
                  <Select
                    placeholder="Select Designation"
                    value={second_designation}
                    onChange={(value) => set_second_designation(value)}
                    style={{ width: "100%" }}
                    options={designation.map((item) => ({
                      value: item,
                      label: item,
                    }))}
                  />

                  {errors?.second_designation && (
                    <>
                      <span style={{ color: "red" }}>
                        {errors?.second_designation[0]}
                      </span>
                    </>
                  )}
                </div>

                {/* Address */}
                <div className="input-box">
                  <label htmlFor="address">Address</label>
                  <Input
                    value={address}
                    placeholder="address"
                    id="address"
                    onChange={(e) => set_address(e.target.value)}
                  />

                  {errors?.address && (
                    <>
                      <span style={{ color: "red" }}>{errors?.address[0]}</span>
                    </>
                  )}
                </div>

                {/* Image */}
                <div className="input-box" style={{ position: "relative" }}>
                  <label htmlFor="address">User Photo</label>
                  <br></br>
                  <label className="upload-box">
                    {" "}
                    <input
                      type="file"
                      id="upload-images"
                      accept="image/*"
                      style={{ height: "0px", overflow: "hidden" }}
                      onChange={handleImageChange}
                    />{" "}
                    <CloudUploadOutlined /> Upload Image
                  </label>
                  {url_image ? (
                    <>
                      <div
                        className="image-box"
                        style={{
                          backgroundImage:
                            "url(" + URL.createObjectURL(url_image) + ")",
                        }}
                      ></div>
                      <button
                        className="image-remove"
                        onClick={() => handleRemoveImage()}
                      >
                        <CloseOutlined />
                      </button>
                    </>
                  ) : (
                    <>
                      {image && (
                        <>
                          <div
                            className="image-box"
                            style={{
                              backgroundImage:
                                "url(" +
                                SIS_STORAGE +
                                "/faculty/" +
                                image +
                                ")",
                            }}
                          ></div>
                        </>
                      )}
                    </>
                  )}

                  {errors?.image && (
                    <>
                      <span style={{ color: "red" }}>{errors?.image[0]}</span>
                    </>
                  )}
                </div>
              </div>

              {/* button */}
              <div className="col-12">
                <div className="input-box">
                  {loader ? (
                    <Button type="primary">
                      <Spin
                        indicator={
                          <LoadingOutlined
                            style={{
                              fontSize: "12px",
                              color: "#fff",
                              marginRight: "5px",
                            }}
                          />
                        }
                      />{" "}
                      Saving
                    </Button>
                  ) : (
                    <Button type="primary" onClick={UPDATE_API}>
                      Update
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default EditFaculty;
