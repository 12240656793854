import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Checkbox,
  Input,
  Radio,
  Select,
  Spin,
  Table,
  notification,
} from "antd";
import "./style.css";
import { TableColumnsType, TableProps } from "antd";
import {
  CloseOutlined,
  CloudUploadOutlined,
  EyeInvisibleOutlined,
  EyeTwoTone,
  LoadingOutlined,
  SmileOutlined,
} from "@ant-design/icons";
import { ADD_COURSE, CATEGORIES_LIST, COURSES_LIST } from "../../apis/apis";
import SisLoader from "../../widgets/loader";
import Item from "antd/es/list/Item";
import { useNavigate } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";

const AddCourse = () => {
  const editorRef = useRef(null);
  const navigate = useNavigate();
  const [loader, set_loader] = useState(false);
  const [errors, set_errors] = useState([]);
  const [cate_list, set_cate_list] = useState([]);
  const [page_loader, set_page_loader] = useState(true);

  // Form State
  const [title, set_title] = useState("");
  const [code, set_code] = useState("");
  const [units, set_units] = useState("");
  const [certificate_programs, set_certificate_programs] = useState(1);
  const [description, set_description] = useState("");
  const [category, set_category] = useState([]);
  const [perquisite_courses, set_perquisite_courses] = useState([]);
  const [image, set_image] = useState("");
  const [table_list, set_table_list] = useState([]);
  // ROLES list
  const CATEGORIES_LIST_API = async () => {
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("token", USER_DATA.token);
    const LIST_API_RESPONSE = await CATEGORIES_LIST(FORM_DATA);
    if (LIST_API_RESPONSE?.data?.status) {
      var filteredData = LIST_API_RESPONSE?.data?.categories.filter(
        (item) => item.soft_delete === 0 && item.status === 1
      );
      set_cate_list(filteredData);
      set_loader(false);
    } else {
      set_loader(false);
    }
  };
  const LIST_API = async () => {
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("token", USER_DATA.token);
    const API_RESPONSE = await COURSES_LIST(FORM_DATA);
    if (API_RESPONSE?.data?.status) {
      var filteredData = API_RESPONSE?.data?.courses.filter(
        (item) => item.soft_delete === 0 && item.status === 1
      );
      set_table_list(filteredData);
      set_loader(false);
      set_page_loader(false);
    } else {
      set_loader(false);
    }
  };

  useEffect(() => {
    CATEGORIES_LIST_API();
    LIST_API();
  }, []);

  const handleImageChange = (e) => {
    const files = e.target.files;
    if (
      (files[0].type === "image/jpeg" ||
        files[0].type === "image/jpg" ||
        files[0].type === "image/png") &&
      files[0].size < 2000001
    ) {
      set_image(files[0]);
    } else {
      notification.open({
        message: "Not Allowed",
        description:
          "Please check image File format must be .jpg or .png and Max. file size 2 Mb",
        icon: <SmileOutlined style={{ color: "red" }} />,
      });
    }
  };
  const handleRemoveImage = (e) => {
    set_image("");
  };

  const ADD_API = async () => {
    set_loader(true);
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("token", USER_DATA.token);
    FORM_DATA.append("title", title);
    FORM_DATA.append("description", editorRef.current.getContent());
    FORM_DATA.append("image", image);
    FORM_DATA.append("certificate_programs", certificate_programs);
    FORM_DATA.append("code", code);
    FORM_DATA.append("units", units);
    FORM_DATA.append("category", [...category]);
    FORM_DATA.append("perquisite_courses", [...perquisite_courses]);
    const API_RESPONSE = await ADD_COURSE(FORM_DATA);
    if (API_RESPONSE.data.status) {
      notification.open({
        message: "Success!!",
        description: "Course Successfully added.",
        icon: <SmileOutlined style={{ color: "green" }} />,
      });
      navigate("/courses-list");
    } else {
      set_errors(API_RESPONSE.data.errors);
      set_loader(false);
    }
  };

  return (
    <div>
      <div className="theme-content-head">
        <div className="theme-content-left-head">
          <h3>Add Course</h3>
        </div>
        <div className="theme-content-right-head"></div>
      </div>
      <div className="common-form">
        {page_loader ? (
          <SisLoader />
        ) : (
          <>
            {errors?.try && (
              <>
                <span style={{ color: "red" }}>{errors?.try[0]}</span>
              </>
            )}
            {errors?.catch && (
              <>
                <span style={{ color: "red" }}>{errors?.catch[0]}</span>
              </>
            )}
            <div className="row">
              <div className="col-12">
                <div className="input-box">
                  <label htmlFor="name">
                    Course Title<i style={{ color: "red" }}>*</i>
                  </label>
                  <Input
                    placeholder="Title"
                    id="title"
                    onChange={(e) => set_title(e.target.value)}
                  />
                  {errors?.title && (
                    <>
                      <span style={{ color: "red" }}>{errors?.title[0]}</span>
                    </>
                  )}
                </div>
                <div className="input-box">
                  <label htmlFor="code">
                    Course Code<i style={{ color: "red" }}>*</i>
                  </label>
                  <Input
                    placeholder="code"
                    id="code"
                    onChange={(e) => set_code(e.target.value)}
                  />
                  {errors?.code && (
                    <>
                      <span style={{ color: "red" }}>{errors?.code[0]}</span>
                    </>
                  )}
                </div>
                <div className="input-box">
                  <label htmlFor="name">
                    Course Description<i style={{ color: "red" }}>*</i>
                  </label>
                  <Editor
                    apiKey="i6i6aki8vkxt19vlfxol49qa6zukk6lry8hgtzka6agthn0x"
                    onInit={(evt, editor) => (editorRef.current = editor)}
                    initialValue=""
                    init={{
                      height: 500,
                      menubar: false,
                      plugins:
                        "anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount imagetools",
                      toolbar:
                        "undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image code media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat",
                      content_style:
                        "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                    }}
                  />
                  {errors?.description && (
                    <>
                      <span style={{ color: "red" }}>
                        {errors?.description[0]}
                      </span>
                    </>
                  )}
                </div>
                <div className="input-box">
                  <label htmlFor="Units">
                    Course Units<i style={{ color: "red" }}>*</i>
                  </label>
                  <Input
                    maxLength={10}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    placeholder="Course Units"
                    id="Units"
                    onChange={(e) => set_units(e.target.value)}
                  />
                  {errors?.units && (
                    <>
                      <span style={{ color: "red" }}>{errors?.units[0]}</span>
                    </>
                  )}
                </div>
                <div className="input-box">
                  <label htmlFor="Units">
                    Program<i style={{ color: "red" }}>*</i>
                  </label>
                </div>
                <div>
                  <Radio.Group
                    onChange={(e) => set_certificate_programs(e.target.value)}
                    defaultValue={certificate_programs}
                  >
                    <Radio value={1}>Under Graduate</Radio>
                    <Radio value={2}>Graduate</Radio>
                    <Radio value={3}>Doctorate</Radio>
                    <Radio value={4}>Certificate Programs</Radio>
                  </Radio.Group>
                  <br></br>
                  {errors?.certificate_programs && (
                    <>
                      <span style={{ color: "red" }}>
                        {errors?.certificate_programs[0]}
                      </span>
                    </>
                  )}
                </div>

                <div className="input-box">
                  <label htmlFor="Select-Role">
                    Select Categories<i style={{ color: "red" }}>*</i>
                  </label>
                  <br></br>
                  <Select
                    mode="multiple"
                    placeholder="Select Categories"
                    value={category}
                    onChange={(value) => set_category(value)}
                    style={{ width: "100%" }}
                    allowClear
                    showSearch
                    filterOption={(inputValue, option) => {
                      return !option.label
                        .toLowerCase()
                        .indexOf(inputValue.toLowerCase());
                    }}
                    options={cate_list.map((item) => ({
                      value: item.id,
                      label: item.title,
                    }))}
                  />

                  {errors?.category && (
                    <>
                      <span style={{ color: "red" }}>
                        {errors?.category[0]}
                      </span>
                    </>
                  )}
                </div>
                <div className="input-box">
                  <label htmlFor="Select-Role">Perquisite courses</label>
                  <br></br>
                  <Select
                    mode="multiple"
                    placeholder="Perquisite courses"
                    value={perquisite_courses}
                    onChange={(value) => set_perquisite_courses(value)}
                    style={{ width: "100%" }}
                    allowClear
                    filterOption={(inputValue, option) => {
                      if(!option.code.toLowerCase().indexOf(inputValue.toLowerCase())){
                          return !option.code.toLowerCase().indexOf(inputValue.toLowerCase())
                      }else{
                          return !option.label.toLowerCase().indexOf(inputValue.toLowerCase())
                      }
                     
                  }
                  }
                 
                  options={table_list.map((item) => ({
                      value: item.id,
                      label: item.title + ' ( ' + item.code + ' )',
                      code : item.code
                  }))}
                  />
                </div>

                <div className="input-box" style={{ position: "relative" }}>
                  <label htmlFor="address">Course Photo</label>
                  <br></br>
                  <label className="upload-box">
                    {" "}
                    <input
                      type="file"
                      id="upload-images"
                      accept="image/*"
                      style={{ height: "0px", overflow: "hidden" }}
                      onChange={handleImageChange}
                    />{" "}
                    <CloudUploadOutlined /> Upload Image
                  </label>
                  {image && (
                    <>
                      <div
                        className="image-box"
                        style={{
                          backgroundImage:
                            "url(" + URL.createObjectURL(image) + ")",
                        }}
                      ></div>
                      <button
                        className="image-remove"
                        onClick={() => handleRemoveImage()}
                      >
                        <CloseOutlined />
                      </button>
                    </>
                  )}
                  {errors?.image && (
                    <>
                      <span style={{ color: "red" }}>{errors?.image[0]}</span>
                    </>
                  )}
                </div>
              </div>
              <div className="col-12">
                <div className="input-box">
                  {loader ? (
                    <>
                      <Button type="primary">
                        <Spin
                          indicator={
                            <LoadingOutlined
                              style={{
                                fontSize: "12px",
                                color: "#fff",
                                marginRight: "5px",
                              }}
                            />
                          }
                        />{" "}
                        Add Course
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button type="primary" onClick={ADD_API}>
                        Add Course
                      </Button>
                    </>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default AddCourse;
