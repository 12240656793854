import React, { useEffect, useState } from "react";
import "./grading.css";
import { useNavigate, useParams } from "react-router-dom";
import { FilterFilled, SearchOutlined } from "@ant-design/icons";
import SisLoader from "../../widgets/loader";
import { ACADEMIC_SEMESTER, SEMESTER_WITH_REGISTERED_COURSE } from "../../apis/apis";
import { Input, Table } from "antd";
// import grading from "./Gradingobject"

function Grading() {
  const navigate = useNavigate();
  const { semester_id } = useParams();
  const [page_loader, set_page_loader] = useState(false);

  const [semesters_list, set_semesters_list] = useState([]);
  const [courses_list, set_courses_list] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  const ACADEMIC_SEMESTER_LIST = async () => {
    const FORM_DATA = new FormData();
    const API_RESPONSE = await ACADEMIC_SEMESTER(FORM_DATA);
    if (API_RESPONSE?.data?.status) {
      set_semesters_list(API_RESPONSE.data.academic_semester);
      if(semester_id){
        SEMESTER_WITH_REGISTERED_COURSE_LIST(atob(semester_id))
      }else{
          if(API_RESPONSE?.data?.academic_semester?.length > 0){
            SEMESTER_WITH_REGISTERED_COURSE_LIST(API_RESPONSE?.data?.academic_semester[0].id)
          }
      }
    } else {
      set_page_loader(false);
    }
  };
  const SEMESTER_WITH_REGISTERED_COURSE_LIST = async (semester_id) => {
    const FORM_DATA = new FormData();
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    FORM_DATA.append("token", USER_DATA.token);
    FORM_DATA.append('semester_id', semester_id);
    const API_RESPONSE = await SEMESTER_WITH_REGISTERED_COURSE(FORM_DATA);
    if (API_RESPONSE?.data?.status) {
      set_courses_list(API_RESPONSE.data.courses);
      setFilteredData(API_RESPONSE.data.courses);
      set_page_loader(false);
    } else {
      set_page_loader(false);
    }
  };

  
  useEffect(() => {
    ACADEMIC_SEMESTER_LIST();
  }, [semester_id]);

  
  const columns = [
    {
      title: "Course",
      render: (text, record) => {
        return <><span style={{ fontSize:"10px", }} onClick={() => navigate('/grading-course/'+btoa(parseInt(record.semester_id)) + '/'+btoa(record.course_id))} className="course_link">{record.course_title}</span></>;
      },
    },
    {
      title: "Code",
      render: (text, record) => {
        return <><span style={{ fontSize:"10px" }}>{record.course_code}</span></>;
      },
    },
    {
      title: "Timing",
      render: (text, record) => {
        var timingData = record?.timing ? JSON.parse(record.timing) : null;
        return <>{timingData && get_timing(record, timingData)}</>;
      },
    },
    {
      title: "Faculty",
      render: (text, record) => {
        return <><span style={{ fontSize:"10px" }}>{record.faculties_name}</span></>;
      },
    },
    {
      title: "# of students",
      render: (text, record) => {
        return <><span style={{ fontSize:"10px" }}>{record.number_of_students}</span></>;
      },
    },
  ];
  const get_timing = (data, selected_days) => {
    return (
      <>
        {selected_days.map((item) => (
          <>
            {item?.start_time && (
              <>
                <span
                      style={{
                        width: "110px",
                        display: "block",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "10px",
                        margin:"3px 0px"
                      }}
                    >
                      {item?.value?.slice(0, 2).toUpperCase()}{" "}
                      {item?.start_time} - {item?.end_time}
                    </span>
              </>
            )}
          </>
        ))}
      </>
    );
  };
  //  Search
  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    var table_data = courses_list;
    const filtered = table_data.filter(item =>
        item.course_title.toLowerCase().includes(query.toLowerCase()) || item.faculties_name.toLowerCase().includes(query.toLowerCase())
       
    );
    if(query === ''){
        setFilteredData(courses_list);
    }else{
        setFilteredData(filtered);
    }
};
  return (
    <>
      <div>
        <div className="theme-content-head">
          <div className="theme-content-left-head">
            <h3>Academic Calendar</h3>
          </div>
          <div className="theme-content-right-head"></div>
        </div>
        <div className="sis-tab-menu">
          {semesters_list?.length > 0 &&
            semesters_list.map((item, index) => (
              <>
                {semester_id ? (
                  <>
                    {parseInt(atob(semester_id)) === item.id ? (
                      <>
                        <span className="active" onClick={() => navigate('/grading/'+btoa(item.id))}>{item.semester_title}</span>
                      </>
                    ) : (
                      <>
                        <span onClick={() => navigate('/grading/'+btoa(item.id))}>{item.semester_title}</span>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {index === 0 ? (
                      <>
                        <span className="active"  onClick={() => navigate('/grading/'+btoa(item.id))}>{item.semester_title}</span>
                      </>
                    ) : (
                      <>
                        <span  onClick={() => navigate('/grading/'+btoa(item.id))}>{item.semester_title}</span>
                      </>
                    )}
                  </>
                )}
              </>
            ))}
          {/*                     
                    <span>Semesters</span>
                    <span>Schedules</span> */}
        </div>
        {page_loader ? (
          <SisLoader />
        ) : (
          <>
            <div className='theme-content-head'>
                <div className='input-box'>
                    <Input
                        type="text"
                        placeholder="Search..."
                        value={searchQuery}
                        onChange={handleSearch}
                    />
                   
                </div>
            </div>
            <div className="sis-tab-content">
            <Table dataSource={filteredData} columns={columns} />;
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default Grading;
