import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ACADEMIC_SEMESTER, FACULTY_SEMESTER_WISE_COURSE, sisDateFormat } from "../../apis/apis";
import SisLoader from "../../widgets/loader";
import { Input, Table } from "antd";

const FacultyClasses = () => {
const { semester_id } = useParams();
const [loader, set_loader] = useState(false);
const [semesters_list, set_semesters_list] = useState([]);
const [courses_list, set_courses_list] = useState([]);
const [filteredData, setFilteredData] = useState([]);
const [searchQuery, setSearchQuery] = useState('');
const [semester_start_date, set_semester_start_date] = useState('');
const [semester_end_date, set_semester_end_date] = useState('');

const ACADEMIC_SEMESTER_LIST = async () => {
     set_loader(true);
    const FORM_DATA = new FormData();
    const API_RESPONSE = await ACADEMIC_SEMESTER(FORM_DATA);
    if (API_RESPONSE?.data?.status) {
      set_semesters_list(API_RESPONSE?.data?.academic_semester);
      set_semester_start_date(API_RESPONSE?.data?.academic_semester[0]?.semester_start_date);
      set_semester_end_date(API_RESPONSE?.data?.academic_semester[0]?.semester_end_date);
      if(semester_id){
       FACULTY_SEMESTER_WITH_REGISTERED_COURSE_LIST(atob(semester_id))
      }else{
          if(API_RESPONSE?.data?.academic_semester?.length > 0){
           FACULTY_SEMESTER_WITH_REGISTERED_COURSE_LIST(API_RESPONSE?.data?.academic_semester[0].id)
          }
      }
    } else {
      set_loader(false);
    }
    set_loader(false);
  };
  
  
  const FACULTY_SEMESTER_WITH_REGISTERED_COURSE_LIST = async (semester_id) => {
    set_loader(true);
    const FORM_DATA = new FormData();
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    FORM_DATA.append("token", USER_DATA.token);
    FORM_DATA.append('semester_id', semester_id);
    FORM_DATA.append('faculty_id', USER_DATA.id);
    const API_RESPONSE = await FACULTY_SEMESTER_WISE_COURSE(FORM_DATA);
    if (API_RESPONSE?.data?.status) {
      set_courses_list(API_RESPONSE?.data?.courses);
      setFilteredData(API_RESPONSE?.data?.courses);
      set_loader(false);
    } else {
      set_loader(false);
    }
  };
  
  useEffect(() => {
    ACADEMIC_SEMESTER_LIST();
  }, [semester_id]);
  
  const columns = [
    {
      title: "Course",
      render: (text, record) => {
        return <><span style={{ fontSize:"10px", }} >{record.course_title}</span></>;
      },
      sorter: (a, b) => a.course_title.localeCompare(b.course_title),
    },
    {
      title: "Code",
      render: (text, record) => {
        return <><span style={{ fontSize:"10px" }}>{record?.course_code}</span></>;
      },
    },
    {
      title: "Timing",
      render: (text, record) => {
        var timingData = record?.timing ? JSON.parse(record?.timing) : null;
        return <>{timingData && get_timing(record, timingData)}</>;
      },
    },
    // {
    //   title: "Faculty",
    //   render: (text, record) => {
    //     return <><span style={{ fontSize:"10px" }}>{record?.faculties_name}</span></>;
    //   },
    // },
    {
      title: "# No. of Students",
      render: (text, record) => {
        return <><span style={{ fontSize:"10px" }}>{record?.number_of_students}</span></>;
      },
      sorter: (a, b) => a.number_of_students.localeCompare(b.number_of_students),
    },
  ];
  const get_timing = (data, selected_days) => {
    return (
      <>
        {selected_days.map((item) => (
          <>
            {item?.start_time && (
              <>
                <span
                      style={{
                        width: "110px",
                        display: "block",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "10px",
                        margin:"3px 0px"
                      }}
                    >
                      {item?.value?.slice(0, 2).toUpperCase()}{" "}
                      {item?.start_time} - {item?.end_time}
                    </span>
              </>
            )}
          </>
        ))}
      </>
    );
  };
  
   //  Search
   const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    var table_data = courses_list;
    const filtered = table_data.filter(item =>
    item.course_title.toLowerCase().includes(query.toLowerCase())  ||  item.course_title.toLowerCase().replace(/\s/g, "").includes(query.toLowerCase()) ||  item.course_code.replace(/\s/g, "").toLowerCase().includes(query.toLowerCase())
    || item.course_code.toLowerCase().includes(query.toLowerCase()) 
    );
    if(query === ''){
        setFilteredData(courses_list);
    }else{
        setFilteredData(filtered);
    }
  };

    return(
        <>
        {loader ? (
    <SisLoader />
  ) : (
    <>
         <div className="col-12">
          <div>
            <h2>
              Classes Schedule
            </h2>
          </div>
          <br></br>
          <div>
            <div className="col-4 faculty-profile-details" style={{display:"flex", flexDirection:"column", padding:"0"}}>
              <label >Semester</label>
              <span style={{padding:"5px 0"}}>{semesters_list[0]?.semester_title}</span>
            </div>
            <div className="col-4 faculty-profile-details"  style={{display:"flex", flexDirection:"column", padding:"0"}}>
            <label>Semester Period</label>
            <h3 style={{padding:"5px 0"}}><span>{sisDateFormat(semester_start_date)} </span> {" - "} <span> {sisDateFormat(semester_end_date)}</span></h3>
            </div>
          </div>
        </div>
        <div className="col-12"> <hr></hr></div>
        <br></br>
        <div className="col-12" style={{marginTop:"30px"}}>
         <div className='theme-content-head'>
                <div className='input-box'>
                  <label  style={{fontSize:"15px",paddingBottom:"5px"}}>Search Course/Course Code</label>
                    <Input
                    style={{width:"200px", marginLeft:"20px"}}
                        type="text"
                        placeholder="Search..."
                        value={searchQuery}
                        onChange={handleSearch}
                    />
                </div>
            </div>
            {/* {filteredData?.length > 0 ?
            <>
            <div className="sis-tab-content">
            <Table dataSource={filteredData} columns={columns} />
            </div>
            </>
              :
              <>
              <p style={{ margin: "50px 0", textAlign: "center", color: "red", fontSize: "24px" }}>Currently No Classes Schedule Yet.</p>
              </>
            } */}
            <div className="sis-tab-content">
            <Table dataSource={filteredData} columns={columns} />
            </div>
            </div>
            </>
  )}
        </>
    )
}

export default FacultyClasses;