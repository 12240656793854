/* eslint-disable no-unused-vars */
import { Editor } from '@tinymce/tinymce-react';
import { Button, Input, Spin, Tree, notification } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import CourseContainer from '../CourseContainer';
import SubSectionContainer from '../SubSectionContainer';
import { DeleteOutlined, DownOutlined, LoadingOutlined, SmileOutlined, UpOutlined } from '@ant-design/icons';
import SisLoader from '../../../../widgets/loader';
import { ADD_SECTION, DELETE_SECTION, EDIT_SECTION, SUBSECTIONS_LIST, UPDATE_SECTION, UPDATE_SECTION2 } from '../../../../apis/apis';
import AddSubSection from './AddSubSection';
import EditSubSection from './EditSubSection';

const EditSection = (props) => {
    const {
        programme_id,
        set_open_section,
        set_page_loader,
        set_add_section_form,
        set_add_section_button,
        section_item,
        section_id
    } = props;

    const editorRef = useRef(null);

    const [sub_page_loader, set_sub_page_loader] = useState(true)
    const [sub_section, set_sub_section] = useState([]);
    const [open_sub_section, set_open_sub_section] = useState(0);
    const [show_form, set_show_form] = useState(false);

    const [title, set_title] = useState('');
    const [courses, set_courses] = useState([]);
    const [unit, set_unit] = useState('');
    const [description, set_description] = useState('');
    const [show_courses, set_show_courses] = useState(false);

    const [section_loader, set_section_loader] = useState(true)

    // Sub section 
    const [show_sub_section, set_show_sub_section] = useState(false);
    const [sections_list, set_sections_list] = useState([]);


    const [errors, set_errors] = useState([]);

    // Button loader
    const [button_loader, set_button_loader] = useState(false);


    // Updat with course_status 
    const [course_status, set_course_status] = useState(false);


    const LIST_API = async () => {
        const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
        const FORM_DATA = new FormData();
        FORM_DATA.append('token', USER_DATA.token);
        FORM_DATA.append('programme_id', programme_id);
        FORM_DATA.append('parent', section_id);
        const API_RESPONSE = await SUBSECTIONS_LIST(FORM_DATA);
        if (API_RESPONSE?.data.status) {
            set_sub_section(API_RESPONSE?.data.sub_sections);
        } else {

        }
    }
    const UPDATE_API = async () => {
        set_button_loader(true)
        const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
        const FORM_DATA = new FormData();
        FORM_DATA.append('token', USER_DATA.token);
        FORM_DATA.append('id', section_id);
        FORM_DATA.append('title', title);
        FORM_DATA.append('description', editorRef.current.getContent());
        FORM_DATA.append('units', unit);
        FORM_DATA.append('courses', courses);
        FORM_DATA.append('type', 'course');
        FORM_DATA.append('programme_id', programme_id);
        FORM_DATA.append('course_status', 1);
        const API_RESPONSE = await UPDATE_SECTION(FORM_DATA);
        if (API_RESPONSE?.data.status) {
            set_open_section(API_RESPONSE?.data.id)
            set_add_section_form(false)
            set_add_section_button(true)
            set_page_loader(true)
            set_show_courses(true)
            notification.open({
                message: 'Success!!',
                description: 'Programme section Successfully Updated  .',
                icon: <SmileOutlined style={{ color: 'green' }} />,
            });
        } else {
            set_errors(API_RESPONSE?.data.errors);
            set_button_loader(false)
        }
    }
    const UPDATE_API2 = async () => {
        set_button_loader(true)
        const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
        const FORM_DATA = new FormData();
        FORM_DATA.append('token', USER_DATA.token);
        FORM_DATA.append('id', section_id);
        FORM_DATA.append('title', title);
        FORM_DATA.append('description', editorRef.current.getContent());
        FORM_DATA.append('units', unit);
        FORM_DATA.append('programme_id', programme_id);
        const API_RESPONSE = await UPDATE_SECTION2(FORM_DATA);
        set_button_loader(false)
    }
    const VIEW_API = async () => {
        const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
        const FORM_DATA = new FormData();
        FORM_DATA.append('id', section_id);
        FORM_DATA.append('token', USER_DATA.token);
        const EDIT_ROLE_API_RESPONSE = await EDIT_SECTION(FORM_DATA);
        const get_sec = EDIT_ROLE_API_RESPONSE?.data?.section;
        set_title(get_sec?.title);
        set_unit(get_sec?.units);
        set_course_status(get_sec?.course_status);
        set_description(get_sec?.description)
        set_show_courses(get_sec?.course_status !== 'null' && get_sec?.course_status ? true : false)
        set_courses(get_sec?.course_status !== 'null' && get_sec?.course_status ? get_sec.courses?.split(',').map(Number) : [])
        set_section_loader(false)
    }
    // useEffect(() => {
    //     LIST_API();
    //     VIEW_API();
    //     set_sub_page_loader(false);
    // }, [])
    useEffect(() => {
        LIST_API();
        VIEW_API();
        set_sub_page_loader(false);
    }, [sub_page_loader])


    const course_status_update = (type) => {
        set_button_loader(true)
        if (type) {
            UPDATE_API2()
            set_course_status(true)
            set_show_courses(true)
            set_show_sub_section(false)
            set_errors([]);
            set_button_loader(false)

        } else {
            UPDATE_API2()
            set_course_status(false)
            set_show_courses(false)
            set_show_sub_section(true)
            set_errors([]);
            set_button_loader(false)
        }
    }
    const DELETE_API = async () => {
        const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
        const FORM_DATA = new FormData();
        FORM_DATA.append('token', USER_DATA.token);
        FORM_DATA.append('id', section_id);
        const API_RESPONSE = await DELETE_SECTION(FORM_DATA);
        if (API_RESPONSE?.data.status) {
            set_add_section_form(false)
            set_add_section_button(true)
            set_page_loader(true)
            notification.open({
                message: 'Success!!',
                description: 'Section Successfully deleted.',
                icon: <SmileOutlined style={{ color: 'green' }} />,
            });

        }

    }
    return (
        <>
        {section_loader ? <SisLoader /> : <>
            <div className='sis-section'>
                <div className='sis-section-head'>
                    <h4> {title ? title : section_item.title} {section_item.status ? <span style={{ color: "#fff", backgroundColor: "green", fontSize: "10px", padding: "3px 8px", borderRadius: "5px" }}> Activate</span> : <span style={{ color: "#fff", backgroundColor: "red", fontSize: "10px", padding: "3px 8px", borderRadius: "5px" }}>Deactivate</span>}</h4>
                    <div>
                        <b onClick={DELETE_API}><DeleteOutlined /></b>
                        {show_form ? <b onClick={() => set_show_form(false)}><DownOutlined /> </b> : <b onClick={() => set_show_form(true)}> <UpOutlined /> </b>}
                    </div>
                </div>


                {show_form && <>
                    <div className='sis-section-container'>
                        <div className='input-box'>
                            <label htmlFor="name">Section Title<i style={{ color: "red" }}>*</i></label>
                            <Input value={title} placeholder="Title" id='title' onChange={(e) => set_title(e.target.value)} />
                            {errors?.title && <><span style={{ color: "red" }}>{errors?.title[0]}</span></>}
                        </div>
                        <div className='input-box'>
                            <label htmlFor="name">Section  Description<i style={{ color: "red" }}>*</i></label>
                            <Editor
                                apiKey='i6i6aki8vkxt19vlfxol49qa6zukk6lry8hgtzka6agthn0x'
                                onInit={(evt, editor) => editorRef.current = editor}
                                initialValue={description}
                                init={{
                                    height: 200,
                                    menubar: false,
                                    plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount imagetools',
                                    toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image code media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat',
                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                }}
                            />
                            {errors?.description && <><span style={{ color: "red" }}>{errors?.description[0]}</span></>}
                        </div>
                        <div className='input-box'>
                            <label htmlFor="Units">Section Units<i style={{ color: "red" }}>*</i></label>
                            <Input value={unit} maxLength={10} onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); } }} placeholder="Programme Units" id='Units' onChange={(e) => set_unit(e.target.value)} />
                            {errors?.units && <><span style={{ color: "red" }}>{errors?.units[0]}</span></>}
                        </div>
                        <div className='input-box'>
                            {button_loader ? <>
                                <span style={{ color: "orange", cursor: "not-allowed", fontWeight: "bold" }} >+ Add Course</span> or <span style={{ color: "orange", cursor: "not-allowed", fontWeight: "bold" }}>+ Add Sub Section</span>
                            </> : <>
                                <span style={{ color: "orange", cursor: "pointer", fontWeight: "bold" }} onClick={() => course_status_update(true)}>+ Add Course</span> or <span style={{ color: "orange", cursor: "pointer", fontWeight: "bold" }} onClick={() => course_status_update(false)}>+ Add Sub Section</span>

                            </>}

                        </div>
                        {show_courses &&
                            <>
                                <CourseContainer set_courses={set_courses} courses={courses} />
                                {errors?.courses && <><span style={{ color: "red" }}>{errors?.courses[0]}</span></>}
                                <div className='input-box' style={{ textAlign: "right" }}>
                                    {button_loader ? <>
                                        <Spin indicator={<LoadingOutlined style={{ fontSize: '12px', color: "#fff", marginRight: "5px" }} />} />
                                        <Button type="primary" disabled>Save</Button>
                                    </> : <Button type="primary" onClick={UPDATE_API}>Save</Button>}

                                </div>
                            </>
                        }
                        {!course_status && <>

                            {sub_section.length > 0 && <>
                                {sub_section.map((item, index) => (
                                    <>
                                        {sub_page_loader ? <SisLoader /> : <>
                                            <EditSubSection sec_title={title} sec_dec={description} sec_unit={unit} section_item={item} sub_section_id={item.id} section_id={section_id} set_open_sub_section={open_sub_section} programme_id={programme_id} set_sub_page_loader={set_sub_page_loader} set_show_sub_section={set_show_sub_section} set_show_courses={set_show_courses} />
                                        </>}

                                    </>
                                ))}

                            </>}
                        </>}
                        {show_sub_section && <>

                            <AddSubSection section_id={section_id} set_open_sub_section={open_sub_section} programme_id={programme_id} set_sub_page_loader={set_sub_page_loader} set_show_sub_section={set_show_sub_section} />
                        </>}

                    </div>
                </>}
            </div>
            </>}
        </>
    );
};

export default EditSection;