import { useEffect, useState } from "react";
import {FACULTY_PROFILE,SIS_STORAGE} from "../apis/apis"

const LayoutFacultyName = () => {

    const[faculty_name, set_Faculty_Name] = useState('');
    const[api_image, set_Api_Image] = useState('');
    const[honorific, set_Honorific] = useState('');


    const FACULTYINFORMATION = async () =>{

        try {
            const FACULTY_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
            const FORM_DATA = new FormData();
            FORM_DATA.append("token", FACULTY_DATA.token);
            FORM_DATA.append("faculty_id", FACULTY_DATA.id);
            const VIEW_FACULTY_NAME_API_RESPONSE = await FACULTY_PROFILE(FORM_DATA);
            if(VIEW_FACULTY_NAME_API_RESPONSE?.data?.status)
            {
                const userData = VIEW_FACULTY_NAME_API_RESPONSE?.data?.faculty_profile;
                set_Faculty_Name(userData?.name);
                set_Api_Image(userData?.image);
                set_Honorific(userData?.honorific);
            }
        } 
        catch (error) {
            console.error("Error fetching Faculty data:", error);
        }

    }

useEffect(()=>{

    FACULTYINFORMATION();

},[]);

    return(
        <>
        <div className="theme-user">
                      <div className="theme-user-image">
                       {api_image && (
                            <div class="theme-user-image">
                                 <img
                              src={SIS_STORAGE + "/faculty/" + api_image}
                              style={{ width: "50px", height: "50px", borderRadius: "50%",  }}
                            ></img>
                            </div>
                      )}
                      </div>
                      <div className="theme-user-detail">
                        <p style={{textTransform: "capitalize", padding: "0 5px 0 10px", wordBreak: "break-word"}}>
                          <span>{honorific + " "+ faculty_name}</span>
                          <span>Faculty</span>
                        </p>
                      </div>
                    </div>
        </>
    )
}

export default LayoutFacultyName;