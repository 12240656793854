import React, { useEffect, useState } from "react";
import { Checkbox, Input, Table, Select, notification } from "antd";
import {
  DeleteOutlined,
  DownOutlined,
  LoadingOutlined,
  SmileOutlined,
  UpOutlined,
} from "@ant-design/icons";
import {
  COURSES_LIST,
  SEMESTER_COURSE_REGISTRATION_ADD,
  SEMESTER_COURSE_REGISTRATION_REMOVE,
  SEMESTER_COURSE_REGISTRATION_UPDATE,
  STUDENTS_ENROLLMENTS_LIST,
} from "../../apis/apis";
import SisLoader from "../../widgets/loader";
import { render } from "@testing-library/react";

const SemesterSubSectionBox = (props) => {
  const {
    item,
    course_ids,
    set_course_ids,
    calendar_id,
    semester_id,
    student_id,
    set_loader,
    section_id,
    sub_section_id,
  } = props;
  const sectionCourse = item?.course_list;
  const [show_form, set_show_form] = useState(false);
  const [table_list, set_table_list] = useState([]);
  const [table_list_2, set_table_list_2] = useState([]);

  const set_sem_course = (e, item) => {
    if (e.target.checked) {
      set_course_ids([...course_ids, item]);
    } else {
      var newsData = course_ids.filter((itemA) => itemA !== item);
      set_course_ids(newsData);
    }
  };

  // Given Credits
  const given_credit = (data) => {
    return (
      <div>
        <div className="input-box">
          {data.checked ? (
            <>
            {data.course_semester_registration ? 
            <>
             {data.course_semester_registration.course_status > 2 ? <>
                <Select defaultValue={data.course_semester_registration?.given_credits} placeholder="--Select Credit--" style={{ width: "100px" }} disabled>
               {/* {Array(parseInt(data.units)).fill().map((item, index) => (
                <>
                  <Select.Option value={index+1}>{index+1}</Select.Option>
                </>
               ))} */}
              </Select>
             </> : <>
                <Select defaultValue={data.course_semester_registration?.given_credits} placeholder="--Select Credit--" style={{ width: "100px" }}  onChange={(value) =>
                          UPDATE_API(
                            calendar_id,
                            semester_id,
                            student_id,
                            data.id,
                            section_id,
                            sub_section_id,
                            data?.children_id,
                            data?.rooms?.id,
                            data?.timing,
                            data?.faculty?.id,
                            value,
                            data?.course_status,
                            data.course_semester_registration?.id
                          )
                        }>
               {Array(parseInt(data.units)).fill().map((item, index) => (
                <>
                  <Select.Option value={index+1}>{index+1}</Select.Option>
                </>
               ))}
              </Select>
             </>}
            </>
             
            : <>
             Not Registered
            </>}
            </>
          ) : (
            <>
              <span style={{ color: "red" }}>Not Reg. in semester</span>
            </>
          )}
        </div>
      </div>
    );
  };

  const get_faculty = (data) => {
    return (
      <>
        {data.rooms && data?.timing && data?.faculty ? (
          <>{data?.faculty?.name}</>
        ) : (
          <>
            <span style={{ color: "red" }}>Not Available</span>
          </>
        )}
      </>
    );
  };
  // Room
  const course_room = (data) => {
    return (
      <div
        style={{
          width: "130px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "12px",
        }}
      >
        {data.rooms && data?.timing && data?.faculty ? (
          <>
            <div className="theme-content-left-head">
              <p>{data.rooms?.room_name}</p>
            </div>
            <div className="theme-content-right-head">
              <h4>{data.rooms?.capacity}</h4>
            </div>
          </>
        ) : (
          <>
            <span style={{ color: "red" }}>Not Available</span>
          </>
        )}
      </div>
    );
  };
  const get_timing = (data,selected_days) => {
    return (
      <>
        {selected_days.map((item) => (
          <>
            {item?.start_time && (
              <>
                {data.rooms && data?.timing && data?.faculty ? (
                  <>
                    <span
                      style={{
                        width: "110px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "12px",
                      }}
                    >
                      {item?.value?.slice(0, 2).toUpperCase()}{" "}
                      {item?.start_time} - {item?.end_time}
                    </span>
                    <br />
                  </>
                ) : (
                  <>
                    <span style={{ color: "red" }}>Not Available</span>
                  </>
                )}
              </>
            )}
          </>
        ))}
      </>
    );
  };

  // Course Status
  const course_status = (data) => {
    return (
      <>
        <div className="input-box">
          {data.rooms && data?.timing && data?.faculty ? (
            <>
            {data?.course_semester_registration && data?.checked ? <>
                {data?.course_semester_registration?.course_status === 2 && <>Registered</>}
                {data?.course_semester_registration?.course_status === 3 && <>Completed</>}
                {data?.course_semester_registration?.course_status === 4 && <>Withdrawn</>}
                {data?.course_semester_registration?.course_status === 5 && <>Graded</>}
                {data?.course_semester_registration?.course_status === 6 && <>Repeated</>}
                {data?.course_semester_registration?.course_status === 7 && <>Partial Completed</>}
            </> : <>
            Not Registered
            </>}
              
           </>
          ) : (
            <>
              <span style={{ color: "red" }}>Not Available</span>
            </>
          )}

          {/* <Select placeholder='--select course status--' style={{width:"150px"}} >
                        <Select.Option value='Completed'>Completed</Select.Option>
                        <Select.Option value='Graded'>Graded</Select.Option>
                        <Select.Option value='Not Registered'>Not Registered</Select.Option>
                        <Select.Option value='Registered'>Registered</Select.Option>
                    </Select> */}
        </div>
      </>
    );
  };
  const ADD_API = async (
    calendar_id,
    semester_id,
    student_id,
    course_id,
    section_id,
    sub_section_id,
    class_section_id,
    room_id,
    timing,
    faculty,
    given_credits,
    course_status
  ) => {
    set_loader(true);

    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("token", USER_DATA.token);
    FORM_DATA.append("calendar_id", calendar_id);
    FORM_DATA.append("semester_id", semester_id);
    FORM_DATA.append("student_id", student_id);
    FORM_DATA.append("course_id", course_id);
    FORM_DATA.append("section_id", section_id);
    FORM_DATA.append("sub_section_id", sub_section_id);
    FORM_DATA.append("class_section_id", class_section_id);
    FORM_DATA.append("room_id", room_id);
    FORM_DATA.append("timing", timing);
    FORM_DATA.append("faculty", faculty);
    FORM_DATA.append("given_credits", given_credits);
    FORM_DATA.append("course_status", course_status);

    const API_RESPONSE = await SEMESTER_COURSE_REGISTRATION_ADD(FORM_DATA);

    if (API_RESPONSE.data.status) {
      notification.open({
        message: "Success!!",
        description: "Student Successfully register in course!",
        icon: <SmileOutlined style={{ color: "green" }} />,
      });
      
    } else {
      notification.open({
        message: "Note!!",
        description: "Student already register in course!",
        icon: <SmileOutlined style={{ color: "yellow" }} />,
      });
      
    }
  };
  const UPDATE_API = async (
    calendar_id,
    semester_id,
    student_id,
    course_id,
    section_id,
    sub_section_id,
    class_section_id,
    room_id,
    timing,
    faculty,
    given_credits,
    course_status,
    id,
  ) => {
    set_loader(true);

    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("token", USER_DATA.token);
    FORM_DATA.append("calendar_id", calendar_id);
    FORM_DATA.append("semester_id", semester_id);
    FORM_DATA.append("student_id", student_id);
    FORM_DATA.append("course_id", course_id);
    FORM_DATA.append("section_id", section_id);
    FORM_DATA.append("sub_section_id", sub_section_id);
    FORM_DATA.append("class_section_id", class_section_id);
    FORM_DATA.append("room_id", room_id);
    FORM_DATA.append("timing", timing);
    FORM_DATA.append("faculty", faculty);
    FORM_DATA.append("id", id);
    FORM_DATA.append("given_credits", given_credits);
    FORM_DATA.append("course_status", course_status);

    const API_RESPONSE = await SEMESTER_COURSE_REGISTRATION_UPDATE(FORM_DATA);

    if (API_RESPONSE.data.status) {
      notification.open({
        message: "Success!!",
        description: "Student course Successfully updated!",
        icon: <SmileOutlined style={{ color: "green" }} />,
      });
      
    } else {
      notification.open({
        message: "Note!!",
        description: "Student already register in course!",
        icon: <SmileOutlined style={{ color: "yellow" }} />,
      });
      
    }
  };
  const REMOVE_API = async (
   id,
   calendar_id,
   semester_id,
   student_id,
   course_id,
   section_id,
   sub_section_id,
   class_section_id,
   room_id,
   timing,
   faculty,
   given_credits,
   course_status,
   checked
  ) => {
    set_loader(true);

    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("token", USER_DATA.token);
    FORM_DATA.append("id", id);
    FORM_DATA.append("calendar_id", calendar_id);
    FORM_DATA.append("semester_id", semester_id);
    FORM_DATA.append("student_id", student_id);
    FORM_DATA.append("course_id", course_id);
    FORM_DATA.append("section_id", section_id);
    FORM_DATA.append("sub_section_id", sub_section_id);
    FORM_DATA.append("class_section_id", class_section_id);
    FORM_DATA.append("room_id", room_id);
    FORM_DATA.append("timing", timing);
    FORM_DATA.append("faculty", faculty);
    FORM_DATA.append("given_credits", given_credits);
    FORM_DATA.append("course_status", course_status);
    FORM_DATA.append("checked", checked);
    const API_RESPONSE = await SEMESTER_COURSE_REGISTRATION_REMOVE(FORM_DATA);

    if (API_RESPONSE.data.status) {
      notification.open({
        message: "Success!!",
        description: "Student Successfully removed in course!",
        icon: <SmileOutlined style={{ color: "green" }} />,
      });
      
    }
  };
  const columns = [
    {
      title: "Courses",
      render: (text, record) => {
        return (
          <>
         
            {record.course_semester_registration ? (
              <>
                <label style={{ position: "relative", course: "pointer" }}>
                  {record.course_semester_registration.course_status > 2 ? <>
                    <Checkbox
                    disabled
                    style={{ position: "absolute", top: "0px", left: "0px" }}
                  />
                  </> : <>
                  <Checkbox
                    checked={record.checked} 
                    onChange={(e) =>
                      REMOVE_API(
                        record?.course_semester_registration?.id,
                        calendar_id,
                        semester_id,
                        student_id,
                        record.id,
                        section_id,
                        sub_section_id,
                        record?.children_id,
                        record?.rooms?.id,
                        record?.timing,
                        record?.checked,
                        record?.faculty?.id,
                        1,
                        1,
                        record?.checked,
                      )
                    }
                    style={{ position: "absolute", top: "0px", left: "0px" }}
                  />
                  </>}

                 
                  <div style={{ minWidth: "350px" }}>
                    <span
                      style={{
                        fontSize: "12px",
                        paddingLeft: "22px",
                        course: "pointer",
                      }}
                    >
                      {" "}
                      {record.title}{" "}
                      <span
                        style={{
                          fontSize: "10px",
                          backgroundColor: "red",
                          color: "#fff",
                          padding: "1px 7px",
                          borderRadius: "5px",
                        }}
                      >
                        SEC-{record.section_name}
                      </span>
                    </span>
                  </div>
                </label>
              </>
            ) : (
              <>
                <label style={{ position: "relative", course: "pointer" }}>
                  {record.rooms && record?.timing && record?.faculty ? (
                    <>
                      <Checkbox
                        onChange={(e) =>
                          ADD_API(
                            calendar_id,
                            semester_id,
                            student_id,
                            record.id,
                            section_id,
                            sub_section_id,
                            record?.children_id,
                            record?.rooms?.id,
                            record?.timing,
                            record?.faculty?.id,
                            1,
                            1
                          )
                        }
                        style={{
                          position: "absolute",
                          top: "0px",
                          left: "0px",
                        }}
                      />
                    </>
                  ) : (
                    <></>
                  )}

                  <div style={{ minWidth: "350px" }}>
                    <span
                      style={{
                        fontSize: "12px",
                        paddingLeft: "22px",
                        course: "pointer",
                      }}
                    >
                      {" "}
                      {record.title}{" "}
                      <span
                        style={{
                          fontSize: "10px",
                          backgroundColor: "red",
                          color: "#fff",
                          padding: "1px 7px",
                          borderRadius: "5px",
                        }}
                      >
                        SEC-{record.section_name}
                      </span>
                    </span>
                  </div>
                </label>
              </>
            )}
          </>
        );
      },
      sorter: (a, b) => a.title.localeCompare(b.title),
    },
    {
      title: "Courses Code",
      dataIndex: "code",
      key: "code",
      sorter: (a, b) => a.code.localeCompare(b.code),
    },
    {
      title: "Total Credits",
      dataIndex: "units",
      key: "units",
    },
    {
      title: "Given Credits",
      render: (text, record) => {
        return given_credit(record);
      },
    },

    {
      title: "Room",
      render: (text, record) => {
        return course_room(record);
      },
    },
    {
      title: "timing",
      render: (text, record) => {
        var timingData = record?.timing ? JSON.parse(record.timing) : null;
        return <>{timingData && get_timing(record, timingData)}</>;
      },
    },
    {
      title: "Faculty",
      render: (text, record) => {
        return get_faculty(record);
      },
    },
    {
      title: "Course Status",
      render: (text, record) => {
        return course_status(record);
      },
    },
  ];
  return (
    <div
      className="sis-section"
      style={{ backgroundColor: "#fff", marginTop: "10px" }}
    >
      <div className="sis-section-head">
        <h4>{item.title}</h4>
        <div>
          Units-{item.units}
          {show_form ? (
            <b onClick={() => set_show_form(false)}>
              <DownOutlined />{" "}
            </b>
          ) : (
            <b onClick={() => set_show_form(true)}>
              {" "}
              <UpOutlined />{" "}
            </b>
          )}
        </div>
      </div>

      {show_form && (
        <>
          <div className="sis-section-container">
          <Table columns={columns} dataSource={sectionCourse} defaultExpandAllRows={true} />
          </div>
        </>
      )}
    </div>
  );
};

export default SemesterSubSectionBox;
