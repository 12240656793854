import React from 'react';

const Dashboard = () => {
    return (
      <>
      <h3>Dashboard for Faculty LU-SIS</h3>
      <p>Coming Soon</p>
      </>
    );
};

export default Dashboard;