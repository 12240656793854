import React, { useEffect, useRef, useState } from 'react';
import { Button, Checkbox, DatePicker, Input, Select, Spin, Table, notification } from 'antd';
import { TableColumnsType, TableProps } from 'antd';
import { CloseOutlined, CloudUploadOutlined, EyeInvisibleOutlined, EyeTwoTone, LoadingOutlined, SmileOutlined } from '@ant-design/icons';
import SisLoader from '../../../widgets/loader';
import Item from 'antd/es/list/Item';
import { useNavigate, useParams } from 'react-router-dom';
import { Editor } from '@tinymce/tinymce-react';
import { ADD_CALENDAR } from '../../../apis/apis';
import dayjs from 'dayjs';

const AddCalendar = () => {
    // Date functions 
    const dateFormat = 'YYYY-MM-DD';
    const DATE_CLASS = new Date();
    const current_month = DATE_CLASS.getMonth() < 9 ? '0'+( DATE_CLASS.getMonth() + 1) : DATE_CLASS.getMonth() ;
    const today_date = DATE_CLASS.getFullYear() + '-' + current_month + '-' + DATE_CLASS.getDate();

    
    const { RangePicker } = DatePicker;
    const editorRef = useRef(null);
    const navigate = useNavigate();
    const [loader, set_loader] = useState(false);
    const [errors, set_errors] = useState([]);

    // Form State
    const [title, set_title] = useState('');
    const [description, set_description] = useState('');
    const [calendar_year, set_calendar_year] = useState('');
    const [calendar_start, set_calendar_start] = useState('');
    const [calendar_end, set_calendar_end] = useState('');



    const select_date = (date, dateString, type) => {
        if(type === 'start_date'){
            set_calendar_start(dateString);
        }else{
            set_calendar_end(dateString);
        }
    }
    const ADD_API = async () => {
        set_loader(true)
        const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
        const FORM_DATA = new FormData();
        FORM_DATA.append('token', USER_DATA.token);
        FORM_DATA.append('title', title);
        FORM_DATA.append('description', editorRef.current.getContent());
        FORM_DATA.append('year_start_date', calendar_start);
        FORM_DATA.append('year_end_date', calendar_end);
        const API_RESPONSE = await ADD_CALENDAR(FORM_DATA);
        if (API_RESPONSE.data.status) {
            notification.open({
                message: 'Success!!',
                description: 'Calendar Successfully added. Now Add semester in Academic Calendar',
                icon: <SmileOutlined style={{ color: 'green' }} />,
            });
            navigate('/edit-calendar/'+btoa(API_RESPONSE.data.id))
        } else {
            set_errors(API_RESPONSE.data.errors);
            set_loader(false)
        }
    }
    return (
        <>
            <div>
                <div className='theme-content-head'>
                    <div className='theme-content-left-head'>
                        <h3>Add Academic Calendar</h3>
                    </div>
                    <div className='theme-content-right-head'>

                    </div>
                </div>
                {/* <div className='sis-tab-menu'>
                    <span className='active'>Academic Calendar</span>
                    <span  onClick={() => navigate('/semester-courses/'+calendar_id)}>Schedule</span>
                </div> */}
                <div className='sis-tab-content'>
                <div className='theme-content-head'>
                            <div className='theme-content-left-head'>
                                <h3>Add Calendar</h3>
                            </div>
                            <div className='theme-content-right-head'>
                                {/* <Button type='primary' onClick={() => navigate('/add-category')}>Add Calendar</Button> */}
                            </div>
                        </div>
                        <div className='input-box'>
                            <label htmlFor="name">Title<i style={{ color: "red" }}>*</i></label>
                            <Input placeholder="Title" id='title' onChange={(e) => set_title(e.target.value)} />
                            {errors?.title && <><span style={{ color: "red" }}>{errors?.title[0]}</span></>}
                        </div>
                        <div className='row'>
                            <div className='col-6'>
                            <label htmlFor="name">Calendar Start Date<i style={{ color: "red" }}>*</i></label>
                            <DatePicker minDate={dayjs(today_date)} onChange={(date, dateString) => select_date(date, dateString, 'start_date')} style={{width:"100%"}} />
                            {errors?.year_start_date && <><span style={{ color: "red" }}>{errors?.year_start_date[0]}</span></>}
                            </div>
                            <div className='col-6'>
                                <label htmlFor="name">Calendar End Date<i style={{ color: "red" }}>*</i></label>
                                <DatePicker minDate={dayjs(today_date)}  onChange={(date, dateString) => select_date(date, dateString, 'end_date')} style={{width:"100%"}}  />
                                {errors?.year_end_date && <><span style={{ color: "red" }}>{errors?.year_end_date[0]}</span></>}
                            </div>
                        </div>
                    
                        <div className='input-box'>
                            <label htmlFor="name">Description<i style={{ color: "red" }}>*</i></label>
                            <Editor
                                apiKey='i6i6aki8vkxt19vlfxol49qa6zukk6lry8hgtzka6agthn0x'
                                onInit={(evt, editor) => editorRef.current = editor}
                                initialValue=""
                                init={{
                                    height: 250,
                                    menubar: false,
                                    plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount imagetools',
                                    toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image code media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat',
                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                }}
                            />
                            {errors?.description && <><span style={{ color: "red" }}>{errors?.description[0]}</span></>}
                        </div>
                        <div className='input-box'>
                            {loader ? <>
                                <Button type="primary"><Spin indicator={<LoadingOutlined style={{ fontSize: '12px', color: "#fff", marginRight: "5px" }} />} /> Add Calendar</Button>
                            </> : <>
                                <Button type="primary" onClick={ADD_API}>Add Calendar</Button>
                            </>}
                        </div>

                     

                </div>
            </div>
        </>
    );
};

export default AddCalendar;