import React, { useEffect, useState } from "react";
import "./layout.css";
import Sider from "antd/es/layout/Sider";
import user_icon from "./../images/user.png";
import { Button, Layout, Menu, Spin, theme } from "antd";
import {
  DashboardOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  LoginOutlined,
  UserOutlined,
  BookOutlined,
  FileOutlined,
  ScheduleOutlined,
  IdcardOutlined,
  SolutionOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import { Content, Header } from "antd/es/layout/layout";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import logoTheme from "./../images/fav.png";
import { CHECK_TOKEN } from "../apis/apis";
import LayoutFacultyName from "./layoutFacultyName";
const SisLayout = () => {
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(false);
  const [selected_menu, set_selected_menu] = useState();
  const [storageData, setStorageData] = useState([]);
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const [login_check, set_login_check] = useState(false);
  const [loader, set_loader] = useState(true);
  const CHECK_TOKEN_API = async () => {
    try {
      const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
     
      const FORM_DATA = new FormData();
      FORM_DATA.append("token", USER_DATA?.token);
      FORM_DATA.append("user_type", "faculty");
      const CHECK_TOKEN_RESPONSE = await CHECK_TOKEN(FORM_DATA);
      if (CHECK_TOKEN_RESPONSE?.data?.status) {
        setStorageData(USER_DATA);
        set_loader(false);
      } else {
        // localStorage.clear();
        // window.location = 'https://dev-students.icore.uk/';
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    CHECK_TOKEN_API();
  }, []);
  useEffect(() => {
    var location_pathname = window.location.pathname;
    if (location_pathname === "/") {
      set_selected_menu(["1"]);
    }
    if (location_pathname === "/profile") {
      set_selected_menu(["2"]);
    }
    if (location_pathname === "/classes") {
      set_selected_menu(["3"]);
    }
    if (location_pathname === "/grades") {
      set_selected_menu(["4"]);
    }
    if (location_pathname === "/faculty-record-attendance-class") {
      set_selected_menu(["5"]);
    }
    if (location_pathname === "/courses-list") {
      set_selected_menu(["6"]);
    }
    if (location_pathname === "/programmes-list") {
      set_selected_menu(["7"]);
    }
    if (location_pathname === "/calendar-list") {
      set_selected_menu(["8"]);
    }
    if (location_pathname === "/registration-list") {
      set_selected_menu(["4"]);
    }
    if (location_pathname === "/enrollments-list") {
      set_selected_menu(["9"]);
    }

    if (location_pathname === "/rooms-list") {
      set_selected_menu(["10"]);
    }
    if (location_pathname === "/facultiesList") {
      set_selected_menu(["11"]);
    }
    if (location_pathname === "/semester-registration") {
      set_selected_menu(["13"]);
    }
    if (location_pathname === "/students-list") {
      set_selected_menu(["14"]);
    }
    if (location_pathname === "/grading") {
      set_selected_menu(["15"]);
    }
    if (location_pathname === "/attendence-setup") {
      set_selected_menu(["21"]);
    }
  });
  return (
    <>
      {loader ? (
        <>
          <div
            style={{
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <Spin />
          </div>
        </>
      ) : (
        <>
          <Layout>
            <Sider trigger={null} collapsible collapsed={collapsed}
             style={{ backgroundColor: " #900000" }}
            >
              <div className="logo-user-box">
                <div className="logo-area">
                  <img className="theme-logo" src={logoTheme} />
                </div>
                {!collapsed && (
                  <>
                    <h2>Lincoln University</h2>
                    <p>Student Information System</p>
                    <LayoutFacultyName />
                  </>
                )}
              </div>
              <Menu
                style={{ backgroundColor: " #900000" }}
                theme="dark"
                mode="inline"
                selectedKeys={selected_menu}
                items={[

                // my Faculty Layout Starts
                  {
                    key: "1",
                    icon: <DashboardOutlined />,
                    label: "Dashboard",
                    onClick: function (e) {
                      navigate("/");
                    },
                  },

                  {
                    key: "2",
                    icon: <UserOutlined />,
                    label: "My Profile",
                    onClick: function (e) {
                      navigate("/profile");
                    },
                  },

                  {
                    key: "3",
                    icon: <IdcardOutlined />,
                    label: "My Classes",
                    onClick: function (e) {
                      navigate("/classes");
                    },
                  },
                  {
                    key: "5",
                    icon: <FileOutlined />,
                    label: "Attendance",
                    onClick: function (e) {
                      navigate("/faculty-record-attendance-class");
                    },
                  },

                  {
                    key: "4",
                    icon: <SolutionOutlined />,
                    label: "Grading",
                    onClick: function (e) {
                      navigate("/grades");
                    },
                  },
                  {
                    key: "12",
                    icon: <LogoutOutlined />,
                    label: "Logout",
                    onClick: function (e) {
                      localStorage.clear();
                      window.location = "https://lusis.lincolnuca.edu/";
                    },
                  },
                ]}
              />
            </Sider>
            <Layout>
              <Header style={{ padding: 0, background: colorBgContainer }}>
                <Button
                  type="text"
                  icon={
                    collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />
                  }
                  onClick={() => setCollapsed(!collapsed)}
                  style={{
                    fontSize: "16px",
                    width: 64,
                    height: 64,
                  }}
                />
              </Header>
              <Content
                style={{
                  margin: "24px 16px",
                  padding: 24,
                  minHeight: window.innerHeight,
                  background: colorBgContainer,
                  borderRadius: borderRadiusLG,
                }}
              >
                <Outlet />
              </Content>
            </Layout>
          </Layout>
        </>
      )}
    </>
  );
};

export default SisLayout;
