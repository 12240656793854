import React, { useEffect, useState } from "react";
import { FACULTY_PROFILE, SIS_STORAGE } from "../../apis/apis";
import SisLoader from "../../widgets/loader";

const FacultyProfile = () => {
    const [loader, set_loader] = useState(false);
    const [name, set_name] = useState("");
    const [phone, set_phone] = useState("");
    const [email, set_email] = useState("");
    const [address, set_address] = useState("");
    const [image, set_image] = useState("");
    const [department, set_academic_division] = useState("");
    const [api_image, set_api_image] = useState("");
    const [designation, set_designation] = useState([]);
    const [second_designation, set_second_designation] = useState("");
    const [joiningDate, set_joiningDate] = useState("");
    const [salary, set_salary] = useState("");
    const [honorific, set_honorific] = useState("");
    const [errors, set_errors] = useState([]);
   
   
    const VIEW_API = async () => {
      try {
        set_loader(true);
        const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
        const FORM_DATA = new FormData();
        FORM_DATA.append("token", USER_DATA.token);
        FORM_DATA.append("faculty_id", USER_DATA.id);
        const VIEW_FACULTY_PROFILE_API_RESPONSE = await FACULTY_PROFILE(FORM_DATA);
        if (VIEW_FACULTY_PROFILE_API_RESPONSE?.data?.status) {
         const userData = VIEW_FACULTY_PROFILE_API_RESPONSE?.data?.faculty_profile;
         
          set_name(userData.name);
          set_phone(userData.phone);
          set_email(userData.email);
          set_address(userData.address);
          set_academic_division(userData.department);
          set_second_designation(userData.designation);
          set_api_image(userData.image);
          set_honorific(userData.honorific);
          set_loader(false);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    useEffect(() => {
      VIEW_API();
    },[]);

// const UPDATE_API = async() =>
// {
//   set_loader(true);
//   const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
//   const FORM_DATA = new FormData();
//   FORM_DATA.append("token", USER_DATA.token);
//   FORM_DATA.append("faculty_id", USER_DATA.id);
//   FORM_DATA.append("name", name);
//   FORM_DATA.append("phone", phone);
//   FORM_DATA.append("email", email);
//   FORM_DATA.append("address", address);
//   FORM_DATA.append("department", department);
//   FORM_DATA.append("designation", second_designation);
//   FORM_DATA.append("honorific", honorific);
//   const API_RESPONSE = await FACULTY_PROFILE_UPDATE(FORM_DATA);
//   if (API_RESPONSE?.data?.status) {
//     notification.open({
//       message: "Success!!",
//       description: "Faculty Details Successfully Updated.",
//       icon: <SmileOutlined style={{ color: "green" }} />,
//     });
//     set_loader(false);
//     navigate("/profile");
//   } else {
//     set_errors(API_RESPONSE?.data?.errors);
//     set_loader(false);
//   }
// }


return(
  <>
  {loader ? (
    <SisLoader />
  ) : (
    <>
      
      <div>
        <div className="row" style={{marginLeft:"10px"}}>
          <div>
            <h2>My Profile</h2>
          </div>
                <div style={{ width: "400px" }}>
                  <br></br>
                  {image ? (
                    <>
                      <div className="image-box">
                        <img
                          src={URL.createObjectURL(image)}
                          frameborder="0"
                          style={{ width: "100%", height: "100%" }}
                        ></img>
                      </div>
                    </>
                  ) : (
                    <>
                      {api_image && (
                        <>
                          <div className="image-box">
                            <img
                              src={SIS_STORAGE + "/faculty/" + api_image}
                              frameborder="0"
                              style={{ width: "100%", height: "150px" }}
                            ></img>
                          </div>
                        </>
                      )}
                    </>
                  )}
              </div>
          <div className="col-12" style={{padding: "15px 0px"}}>
            <h2 style={{ textTransform: "capitalize", color: "#555" }}>
              {honorific}{" "}{name}
            </h2>
            </div>
        </div>
        <div style={{ marginTop: "15px" }}>
          <div className="personal-details" style={{marginLeft:"10px"}}>Personal Information</div>
          <div>
            <div className="col-4 faculty-profile-details">
              <p style={{marginBottom:"8px"}}>Email</p>
              <span>
                {email}
              </span>
            </div>
            <div className="col-2 faculty-profile-details"> 
              <p className="mobile" style={{marginBottom:"8px"}}>Mobile</p>
              <span>
                {phone}
              </span>
            </div>
            <div className="col-6 faculty-profile-details">
              <p className="address" style={{marginBottom:"8px"}}>Address</p>
              <span>
                {address}
              </span>
            </div>
          </div>
        </div>
        <div className="col-12"  style={{margin:"20px 0px"}}> <hr></hr></div>
        <br></br>
        <div style={{ marginTop: "15px" }}>
          <div>
            <div className="col-4 faculty-profile-details">
              <p className="academic" style={{marginBottom:"8px"}}>Academic Division</p>
              <span>
                {department}
              </span>
            </div>
            <div className="col-4 faculty-profile-details">
              <p className="designation" style={{marginBottom:"8px"}}>Designation</p>
              <span>
                {second_designation}
              </span>
            </div>
          </div>
        </div>
        <div className="col-12" style={{margin:"20px 0px"}}> <hr></hr></div>
      </div>
     
    </>
  )}
</>
);
};

export default FacultyProfile;